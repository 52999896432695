<template>
  <head-meta service="MyRenderer" />
  <div class="landing-view">
    <LpHeader />
    <main class="main">
      <NuxtPage />
    </main>
    <Footer />
  </div>
</template>

<script setup lang="ts">
import('@/assets/css/tailwind.css')
</script>

<style lang="scss" scoped>
.landing-view {
  background-color: #fbfbfb;
  width: 100%;
  height: 100%;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;

  > .main {
    flex-grow: 1;
  }
}
</style>
